import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { AnimatePresence, motion } from "framer-motion"
import parse from "html-react-parser"

const Section = styled.section`
  ${tw`relative py-c120 md:py-c96 2xl:py-c120`}
`
const Headline = styled.h2`
  ${tw`relative font-bold text-black font-ubuntu text-42 md:text-40 lg:text-48 2xl:text-60 pb-c40 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const Accordion = styled.div`
  ${tw`relative px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const AccordionBox = styled(motion.div)`
  ${tw`relative overflow-hidden pt-c25 pb-c15`}
  box-shadow: inset 0px -6px 0px #F0F0F5;
`
const AccordionTitleWrap = styled(motion.div)`
  ${tw`relative flex items-center cursor-pointer pb-c15`}
`
const AccordionTitle = styled(motion.p)`
  ${tw`relative w-4/5 font-bold font-ubuntu text-customGrey text-20 md:text-24 xl:text-30`}
`
const AccordionDescription = styled(motion.div)`
  ${tw`relative font-normal font-firacode text-14 md:text-16 md:w-2/3`}
`
const Arrow = styled(motion.span)`
  ${tw`absolute block font-bold transform right-2 md:right-8 lg:right-32 font-firacode text-24 text-customGrey`}
`

const WpAcfAccordionModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfAccordionBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""

  const [expanded, setExpanded] = useState(false)

  const transition = {
    duration: 0.8,
    ease: [0.04, 0.62, 0.23, 0.98],
  }
  const arrowVariants = {
    open: {
      rotate: -180,
      transition: transition,
    },
    init: {
      rotate: -270,
      transition: transition,
    },
  }
  const accordionVariants = {
    open: { opacity: 1, height: "auto", transition: transition },
    init: { opacity: 0, height: 0, transition: transition },
  }

  return (
    <Section id={sectionId}>
      <Headline>{parse(blockData.headline)}</Headline>
      <Accordion>
        {blockData.items.map((item, index) => {
          const isOpen = index === expanded
          return (
            <AccordionBox key={`accordion-${index}`}>
              <AccordionTitleWrap
                initial={false}
                onClick={() => setExpanded(isOpen ? false : index)}
              >
                <AccordionTitle>{parse(item.title)}</AccordionTitle>
                <Arrow
                  initial="init"
                  animate={isOpen ? "open" : "init"}
                  exit="init"
                  variants={arrowVariants}
                >{`->`}</Arrow>
              </AccordionTitleWrap>
              <AnimatePresence initial={false}>
                <AccordionDescription
                  initial="init"
                  animate={isOpen ? "open" : "init"}
                  exit="init"
                  variants={accordionVariants}
                >
                  {item.description}
                </AccordionDescription>
              </AnimatePresence>
            </AccordionBox>
          )
        })}
      </Accordion>
    </Section>
  )
}

WpAcfAccordionModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfAccordionModuleBlock
